/** @jsx jsx */
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import ResponsiveImage from "./ResponsiveImage"

export const fragment = graphql`
  fragment ImageFields on ContentfulAsset {
    title
    file {
      url
      details {
        image {
          width
          height
        }
      }
    }
  }

  fragment FixedImageFields on ContentfulAsset {
    fixed {
      ...GatsbyContentfulFixed
    }
  }

  fragment FluidImageFields on ContentfulAsset {
    fluid {
      ...GatsbyContentfulFluid
    }
  }
`

function Image({ aspectRatio, fluid, fixed, file, title, roundedImage }) {
  if (fixed) {
    return <GatsbyImage fixed={fixed} sx={{ height: "100%", width: "100%" }} />
  }
  if (fluid) {
    if (aspectRatio) {
      fluid.aspectRatio = parseFloat(aspectRatio)
    }
    return <GatsbyImage fluid={fluid} sx={{ height: "100%", width: "100%" }} />
  }
  if (file) {
    if (aspectRatio) {
      aspectRatio = parseFloat(aspectRatio)
    }
    return <ResponsiveImage alt={title} aspectRatio={aspectRatio} file={file} />
  }

  return null
}

Image.defaultProps = {
  aspectRatio: undefined,
  file: null,
  fixed: null,
  fluid: null,
  title: undefined,
}

Image.propTypes = {
  aspectRatio: PropTypes.number,
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
    details: PropTypes.shape({
      image: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  fixed: PropTypes.object,
  fluid: PropTypes.object,
  title: PropTypes.string,
}

export default Image
