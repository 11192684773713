/** @jsx jsx */

import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"
import { jsx } from "theme-ui"

function ResponsiveImage({
  alt,
  aspectRatio,
  file: {
    url,
    details: {
      image: { width, height },
    },
  },
}) {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  /* eslint-disable */
  const ratio = aspectRatio || height / width
  /* eslint-enable */

  return (
    <div
      ref={ref}
      sx={{
        height: "inherit",
        maxHeight: [270, "unset"],
        position: "relative",
        width: "100%",
      }}
    >
      <div sx={{ pb: `${ratio * 100}%` }} />
      <picture>
        {inView ? (
          <img
            alt={alt}
            src={url}
            sx={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              objectFit: "contain",
              objectPosition: "center center",
              opacity: 1,
              animation: `fadeIn 0.3s ease-in-out`,
              "@keyframes fadeIn": {
                "0%": {
                  opacity: 0,
                },
                "100%": {
                  opacity: 1,
                },
              },
            }}
          />
        ) : null}
      </picture>
      <noscript>
        <picture>
          <img
            alt={alt}
            src={url}
            sx={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
              opacity: 1,
              animation: `fadeIn 0.3s ease-in-out`,
              "@keyframes fadeIn": {
                "0%": {
                  opacity: 0,
                },
                "100%": {
                  opacity: 1,
                },
              },
            }}
          />
        </picture>
      </noscript>
    </div>
  )
}

ResponsiveImage.defaultProps = {
  aspectRatio: undefined,
}

ResponsiveImage.propTypes = {
  alt: PropTypes.string.isRequired,
  aspectRatio: PropTypes.number,
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
    details: PropTypes.shape({
      image: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default ResponsiveImage
